import React from 'react'
import { Link } from "gatsby"
import Logo from "../components/logo"
import Header from "../components/header"
import Container from "../components/container"
import * as logobarstyles from "./logo-bar.module.css"

export default function LogoBar() {
    return (
        <section className={logobarstyles.mainNav}>
            <Link to="/">
                <Logo />
            </Link>
            <section className={logobarstyles.mymottovisible}>
                <Container>
                    <div className={logobarstyles.mymotto}>
                        <Container>
                            <Header headerText="Better Science for Forensics" classTag="h6" headingTag="h6" />
                            <p className={logobarstyles.tagline}>Products For Labs | DNA Services | DNA Expert Witness</p>
                        </Container>
                    </div>
                </Container>
            </section>
        </section>
    )
}