import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

export default function GenButton({ link, button, rel, target, color}) {
    let btnClassName = "gen-button " + color
    let computedLink;
    if(target === "_self"){
        computedLink = <Link to={"/"+link} rel={rel} className={btnClassName}>{button}</Link>;
    } else {
        computedLink = <a href={link} rel={rel} target={target} className={btnClassName}>{button}</a>;
    }

    return <span>{computedLink}</span>

}

GenButton.propTypes = {
    link: PropTypes.string,
    button: PropTypes.string,
    rel: PropTypes.string,
    target: PropTypes.string,
    color: PropTypes.string,
}

GenButton.defaultProps = {
    link: "",
    button: "Learn More",
    rel: "noopener",
    target: "_self",
    color: "blue",
}