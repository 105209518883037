import React from "react"
import Container from "../components/container"
import { Link} from "gatsby"
import * as footerstyles from "./footer.module.css"

export default function Footer(){
    let newDate = new Date()
    newDate = newDate.getFullYear()
   
    return (
        <>
        <footer className="center-text">
            <Container>
                <p className="smallTitle">Independent Forensics. Copyright 2002-{newDate}. <Link className={footerstyles.whitetext} to="/privacy-policy/">Privacy Policy.</Link> <a href="https://us1.campaign-archive.com/home/?u=29f536a88cece7bfe1cea8011&id=5713eb4fb4" target="_blank" rel="noopener noreferrer" className={footerstyles.whitetext}>Join our mailing list.</a></p>
            </Container>
        </footer>
        </>
    )
}