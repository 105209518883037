import React from 'react'
import Container from '../components/container'
import { Link } from "gatsby"
import * as socialstyles from "./social.module.css"

export default function Social() {
    return (
        <section className={socialstyles.socialbar}>
            <Container>
                <div className={socialstyles.flexbar}>
                    <div className={socialstyles.iconsbar}>
                        <a href="https://www.youtube.com/channel/UCRIjv6hQXV2JPB4YmxcwADw/videos" rel="noopener noreferrer" target="_blank" style={{textDecoration: "none"}}>
                            <div className={socialstyles.iconsizebar}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M10.07,13.65l3.74-2.15L10.07,9.35Z" style={{fill: '#fff'}}/><path d="M11.5,0A11.5,11.5,0,1,0,23,11.5,11.5,11.5,0,0,0,11.5,0Zm7.19,11.51a18.78,18.78,0,0,1-.3,3.46,1.83,1.83,0,0,1-1.27,1.27,44.53,44.53,0,0,1-5.62.29,41.76,41.76,0,0,1-5.62-.31A1.8,1.8,0,0,1,4.61,15a18.72,18.72,0,0,1-.3-3.46A18.78,18.78,0,0,1,4.61,8,1.86,1.86,0,0,1,5.88,6.76a44.53,44.53,0,0,1,5.62-.29,41.68,41.68,0,0,1,5.62.31A1.8,1.8,0,0,1,18.39,8,18,18,0,0,1,18.69,11.51Z" style={{fill: '#fff'}}/></g></g></svg>
                            </div>
                            <span className={socialstyles.icontext}>YouTube</span>
                        </a>
                        <a href="https://www.facebook.com/IndependentForensicsDNA/" rel="noopener noreferrer" target="_blank" style={{textDecoration: "none"}}>
                            <div className={socialstyles.iconsizebar}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M23,11.5A11.5,11.5,0,1,0,11.5,23h.2V14.05H9.23V11.17H11.7V9.05a3.46,3.46,0,0,1,3.69-3.8,22,22,0,0,1,2.22.11V7.93H16.1c-1.19,0-1.42.57-1.42,1.4v1.83h2.85L17.16,14H14.68v8.52A11.52,11.52,0,0,0,23,11.5Z" style={{fill: '#fff'}}/></g></g></svg>
                            </div>
                            <span className={socialstyles.icontext}>Facebook</span>
                        </a>
                        <a href="tel:7082341200"> 
                            <div className={socialstyles.iconsizebar}>
                                <img src="/icons/phone.svg" alt="phone icon"/>
                            </div>
                            <span className={socialstyles.icontext}>Phone</span>
                        </a>
                        <a href="mailto:info@ifi-test.com">
                            <div className={socialstyles.iconsizebar}>
                                <img src="/icons/arroba.svg" alt="phone icon"/>
                            </div>
                            <span className={socialstyles.icontext}>Email</span>
                        </a>
                    </div>
                    <div className={socialstyles.iconsbar}>
                            <Link to="/search/" className="gen-button-small white" style={{textDecoration: "none", fontSize: "1.4rem"}}>
                            <div className={socialstyles.iconsizebar}>
                           <svg height="16px" id="Layer_1" version="1.1" viewBox="0 0 25 6" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M15.7,14.3l-3.105-3.105C13.473,10.024,14,8.576,14,7c0-3.866-3.134-7-7-7S0,3.134,0,7s3.134,7,7,7  c1.576,0,3.024-0.527,4.194-1.405L14.3,15.7c0.184,0.184,0.38,0.3,0.7,0.3c0.553,0,1-0.447,1-1C16,14.781,15.946,14.546,15.7,14.3z   M2,7c0-2.762,2.238-5,5-5s5,2.238,5,5s-2.238,5-5,5S2,9.762,2,7z"/></svg>
                           </div>
                            <span>Search</span>
                            </Link>
                    </div>
                </div> 
            </Container>
        </section>
    )
}