import React, { useState } from "react";
import Container from "../components/container"
import PropTypes from "prop-types"
import * as popstyles from "./popup.module.css"
import { connect } from 'react-redux';

const Message = ({message}) => {

    let alertColor = message === "added" ? popstyles.add :popstyles.sub;

    return (
        <div className={[popstyles.message, alertColor].join(" ")}>
            <Container>
            {message}
            </Container>
        </div>
    )
}

const Popup = ({isDarkMode, cart, dispatch}) => {

    // check if cart is the same as quantity
    // check if the cart has items and if it's less or
    // more depending on the alert
    // each message has a boolean
    // each message turns of

    const [curMessages, setCurMessages] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const alerts = {
        add: "added",
        sub: "removed"
    }

    let itemReducer = function(el) {
       
        return (
            el.reduce((acc, cur) => {
                return acc + cur.qty;
            }, 0)
        )

    }

    function addArray (item) {
        let newArr = [];
        newArr.push(item);
        return newArr
    }

    if(itemReducer(cart)) {

        // console.log("quantity is true")
        let curQuantity = 0;
        let curArr = [];
        curQuantity = itemReducer(cart)

        if(curQuantity > 0) {
            // console.log("quantity is greater than 0")
            if(curQuantity > quantity) {
                // console.log(quantity);
                curArr = addArray(alerts.add);
                setQuantity(curQuantity);
                setCurMessages([...curMessages, ...curArr])
            } else if (curQuantity < quantity) {
                curArr = addArray(alerts.sub);  
                setQuantity(curQuantity);  
                setCurMessages([...curMessages, ...curArr])         
            } 
        }

    }

    const deleteItem = () => {
        let newArr, curArr = [];
        curArr = [...curMessages]
        // console.log("curArr: ", curArr)
        newArr = curArr.slice(1);
        // console.log("newArr: ", newArr)
        // console.log("timer is on")
        setCurMessages([...newArr])
        
    }

    if(curMessages.length > 0) {
        // console.log("there are messages to delete")
        setTimeout(deleteItem, 700);
    }

    return (
         <div className={popstyles.popup}>
            {curMessages && curMessages.map((el, index) => (<Message key={index} message={el} />))}
         </div>
    )
}

export default connect(state => ({
    isDarkMode: state.app.isDarkMode,
    cart: state.app.cart
  }), null)(Popup)

Message.propType = {
    message: PropTypes.string
}

