import React, { useState } from "react"
import { connect } from 'react-redux';
import {Link} from "gatsby"
import * as cartStyles from './cart.module.css'
import Container from "../components/container"

const Cart = ({isDarkMode, cart, dispatch}) => {
    
    const [showCart, setShowCart] = useState(true)
    let curCart = [...cart];
    let curTotal = 0;

    let itemReducer = function(el) {
       
        return (
            el.reduce((acc, cur) => {
                return acc + cur.qty;
            }, 0)
        )

    }

    let curNumberItems = cart.length <= 1 ? cart.length < 1 ? 0 : cart[0].qty : itemReducer(cart) ;

    if(curCart.length > 0){
        
        curCart.forEach(el => {
            let sum = el.qty * el.price;
            curTotal += sum;
        })

    } 

    const cartDetails = <React.Fragment>
                            <div>
                                <div style={{margin: "0 2rem"}}>
                                    <p>{curNumberItems}qty <br/>${curTotal} USD</p>
                                </div>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Link to="/checkout/"><button className={[cartStyles.cartmenubtn, "blue"].join(" ")}>Checkout</button></Link>
                            </div>
                        </React.Fragment>
    
    return (
        <React.Fragment>
            <section className={(curCart.length > 0 ? ["show", cartStyles.cartbackground, "small"].join(" ") : "hide")}>
            <Container>
            <div className={cartStyles.cartsection}>
                    <div>
                        <button className="gen-button-small white" onClick={(el) => setShowCart(!showCart)}>{showCart ? "X" : "O"}</button>
                    </div>
                    { showCart && cartDetails}
                </div>
            </Container>
        </section>
        </React.Fragment>
    )
}

export default connect(state => ({
    isDarkMode: state.app.isDarkMode,
    cart: state.app.cart
  }), null)(Cart)