import React from "react"
import Container from "../components/container"
import MainNav from "../components/mainNav"
import SocialBar from "../components/social"
import Footer from "../components/footer"
import Cart from "../components/cart"
import Msg from "../components/important-msg"
import Popup from "../components/popup"

export default function Layout({children}) {
    return (
        <div>
            <Msg />
            <header>
                <SocialBar />
                <MainNav />
            </header>
            <Container>
                {children}
            </Container>
            <Footer />
            <Cart />
            <Popup/>
        </div>
    )
}

