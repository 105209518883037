// extracted by mini-css-extract-plugin
export var buttonsmenu = "mainNav-module--buttonsmenu--pO80S";
export var contactbtn = "mainNav-module--contactbtn--EQSg-";
export var exitmenu = "mainNav-module--exitmenu--bcKWC";
export var footerlinks = "mainNav-module--footerlinks--adGcg";
export var grouplink = "mainNav-module--grouplink--rMiUz";
export var grouplink2 = "mainNav-module--grouplink2--gWoW0";
export var headerlinks = "mainNav-module--headerlinks--Aqbap";
export var insidecontainer = "mainNav-module--insidecontainer--MTFTZ";
export var linkbody = "mainNav-module--linkbody--i-L4o";
export var littlemenu = "mainNav-module--littlemenu--MK0Pm";
export var mainNavMenu = "mainNav-module--mainNavMenu--63WjQ";
export var megamenu = "mainNav-module--megamenu--5btDZ";
export var myNav = "mainNav-module--myNav--ev2Od";
export var navbutton = "mainNav-module--navbutton--J7ooS";
export var navbuttonspecial = "mainNav-module--navbuttonspecial--cKKPQ";
export var showlittlemenu = "mainNav-module--showlittlemenu--hP34J";