import React from "react"
import Container from "../components/container"
import * as importantMsgStyles from "./important-msg.module.css"

export default function ImportantMsg() {
    const msg1 = "ISFG 2024, ISHI 2024, MAFS 2024";
    
    const lnk1 = "https://www.ishinews.com/"
    
    return (
        <section className={importantMsgStyles.important}>
            <Container>
                    <p className={importantMsgStyles.importanttxt}>{msg1} <a className={importantMsgStyles.importantlink} href={lnk1}>Learn More</a></p>
            </Container>
        </section>
    )
}
