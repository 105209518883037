import React from "react"
import PropTypes from "prop-types"

export default function Header({headerText, headingTag, classTag}) {
    const Title = headingTag;
    return <Title className={classTag}>{headerText}</Title>
}

Header.propTypes = {
 headerText: PropTypes.string,
 headingTag: PropTypes.string,
 classTag: PropTypes.string
}

Header.defaultProps = {
headerText: "Main Heading",
headingTag: "h1",
classTag: "h2"
}