import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Container from "../components/container"
import LogoBar from "../components/logo-bar"
import GenButton from "../components/gen-button"
import Header from "../components/header"
import * as mainnavstyles from "./mainNav.module.css"

export default function MainNav() {
    const data = useStaticQuery(graphql`
        query{
            allMenusJson {
                edges {
                    node {
                        name
                        links_header {
                            name
                            handle
                            rel
                            target
                        }
                        links_body {
                            group_title
                            group_links {
                                name
                                svg
                                handle
                            }
                        }
                        links_footer {
                            name
                            handle
                            rel
                            target
                        }
                    }
                }
            }
        }
    `)

    // declare a new state variable, which we'll call "open"
    // {more: false, services: false, kits: false }
    const [open, setOpen] = useState({more: false, services: false, kits: false });
    const [menu, setMenu] = useState("");
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    
    let megaMenu, headMenu, bodyMenu, footerMenu, titleMenu;
    
    function openMegaMenu(menu) {
        setMenu(menu)
        let curMenu = menu.toLowerCase();

        if(curMenu === "more") {
            if(open.more) {
                setOpen({more: false, services: false, kits: false })
            } else {
                setOpen({more: true, services: false, kits: false })
            }
        } else if(curMenu === "services") {
            if(open.services) {
                setOpen({more: false, services: false, kits: false })
            } else {
                setOpen({more: false, services: true, kits: false })
            }
        } else if(curMenu === "kits") {
            if(open.kits) {
                setOpen({more: false, services: false, kits: false })
            } else {
                setOpen({more: false, services: false, kits: true })
            }
        } else {
            return null;
        }
        
    }

    const curMenu = data.allMenusJson.edges.find(n => {
        return n.node.name.includes(menu)
    })

    if(!curMenu) {
        return null;
    }

    // console.log(open, menu, curMenu)

    if(open.more || open.services || open.kits) {
        let selectedMenu = curMenu.node
        
        titleMenu = <Header headerText={selectedMenu.name} headingTag="h3" classTag="h2 center-text"  />

        if(selectedMenu.links_header.length > 0 && selectedMenu.links_header[0].handle.length > 0) {
            headMenu = <div className={mainnavstyles.headerlinks}>
                {selectedMenu.links_header.map((el, index) => (
                    <GenButton key={index} link={el.handle} button={el.name} rel={el.rel} target={el.target} />
                ))}
            </div>
        } else {
            headMenu = null;
        }

        if(selectedMenu.links_body.length > 0) {
            bodyMenu = [];
            selectedMenu.links_body.forEach((el, index) => {
                let link = <div key={index} className={mainnavstyles.grouplink}>
                    <Header headerText={el.group_title} classTag="h6" headingTag="h5" />
                    {el.group_links.map((sel, index) => (
                        <Link key={index} to={sel.handle}>{sel.name}</Link>
                    ))}
                    </div>;
                bodyMenu.push(link)
            })
            
        } else {
            bodyMenu = null;
        }

        if(selectedMenu.links_footer.length > 0 && selectedMenu.links_footer[0].handle.length > 0) {
            footerMenu = <div className={mainnavstyles.headerlinks}>
                {selectedMenu.links_footer.map((el, index) => (
                    <GenButton key={index} link={el.handle} button={el.name} rel={el.rel} target={el.target} />
                ))}
            </div>
        } else {
            footerMenu = null;
        }

        megaMenu = <div className={mainnavstyles.megamenu}>
            <button className={[mainnavstyles.exitmenu , "danger"].join(" ")} onClick={()=>{setOpen({more: false, services: false, kits: false })}}>X</button>
            <div className={mainnavstyles.insidecontainer}>
                <Container>
                {titleMenu}
                {headMenu}
                <div className={mainnavstyles.linkbody}>
                    {bodyMenu}
                </div>
                {footerMenu}
                </Container> 
            </div>
            </div>
    } 

    return (
        <nav className={mainnavstyles.myNav}>
            <Container>
                <LogoBar />
            </Container>
            <Container>
                <div className={mainnavstyles.mainNavMenu}>
                    <div className={mainnavstyles.buttonsmenu}>
                        <button className={[mainnavstyles.showlittlemenu, mainnavstyles.navbutton].join(" ")} onClick={()=>{setShowMobileMenu(!showMobileMenu)}}>{showMobileMenu ? "Hide Menu" : "Show Menu"}</button>
                        <div className={(showMobileMenu ? "" : mainnavstyles.littlemenu)}>
                            <button className={mainnavstyles.navbutton} onClick={(e) => openMegaMenu("More", e)}>{open.more ? "Close" : "Home"}</button>
                            <button className={mainnavstyles.navbutton} onClick={(e) => openMegaMenu("Kits", e)}>{open.kits ? "Close" : "Products"}</button>
                            <button className={mainnavstyles.navbutton} onClick={(e) => openMegaMenu("Services", e)}>{open.services ? "Close" : "Services"}</button>
                            <div className={mainnavstyles.contactbtn}>
                            <Link className={[mainnavstyles.navbuttonspecial, "special"].join(" ")} to="/contact-us/">Contact us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            {megaMenu}
        </nav>
    )
}